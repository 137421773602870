import { useMsal } from '@azure/msal-react';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { getToken } from 'Auth';
import React, {
    useContext,
    ReactNode,
    useEffect,
    useRef,
    RefObject,
    useState,
} from 'react';
import { useConfigContext } from './ConfigProvider';
import { FEATURE_IDS } from 'constants/identifiers';

interface INotificationsContext {
    hubConnection: HubConnection | null;
}

const NotificationContext = React.createContext<INotificationsContext>({
    hubConnection: null,
});

const useNotificationContext = () => useContext(NotificationContext);

interface NodeProviderProps {
    children?: ReactNode;
}

const NotificationProviderComponent = ({ children }: NodeProviderProps) => {
    const [hubConnection, setHubConnection] = useState<HubConnection | null>(
        null
    );
    const hubConnectionRef = useRef<HubConnection | null>(null);
    const { instance } = useMsal();

    useEffect(() => {
        getToken(instance).then(async (authToken: string) => {
            const connection = new HubConnectionBuilder()
                .withUrl('/hubs/notification', {
                    accessTokenFactory: () => authToken,
                })
                .build();

            try {
                await connection.start();
                hubConnectionRef.current = connection;
                setHubConnection(connection);
            } catch (err) {
                console.error(err);
            }
        });
        return () => {
            hubConnectionRef.current?.stop();
        };
    }, []);

    const contextValues = {
        hubConnection: hubConnection,
    };

    return (
        <NotificationContext.Provider value={contextValues}>
            {children}
        </NotificationContext.Provider>
    );
};

const NotificationProvider = ({ children }: NodeProviderProps) => {
    const { featureFlags } = useConfigContext();

    return featureFlags[FEATURE_IDS.NOTIFICATIONS] ? (
        <NotificationProviderComponent>
            {children}
        </NotificationProviderComponent>
    ) : (
        <>{children}</>
    );
};

export { NotificationProvider as default, useNotificationContext };
