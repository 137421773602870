import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './footer.css';

const Footer = () => {
    return (
        <Row
            className="application-footer-wrapper"
            role="contentinfo"
            aria-label="Copyright Information"
        >
            <Col className="content-wrapper">
                <span>© 2021 Alignment Healthcare. All Rights Reserved.</span>
                <a
                    href="https://www.alignmenthealthcare.com/Privacy"
                    target="_new"
                >
                    Privacy Policy &amp; Terms of Use
                </a>
            </Col>
        </Row>
    );
};

export default Footer;
