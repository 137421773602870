import { Dispatch, useCallback, useEffect, useRef, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FilterList } from '@mui/icons-material';
import {
    Alert,
    Button,
    Card,
    CardContent,
    Collapse,
    Divider,
    Skeleton,
} from '@mui/material';
import classNames from 'classnames';
import { isEmpty, isNil } from 'lodash';
import { DateTime } from 'luxon';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import {
    getNotifications,
    getUnreadNotificationsCount,
} from 'api/notifications';
import { getToken } from 'Auth';
import { useNotificationContext } from 'contexts/NotificationsProvider';
import NotificationType from 'enums/Notifications/NotificationType';
import Notification from 'interfaces/Notification';
import NotificationFilter from 'interfaces/NotificationFilter';
import NotificationDetails from './NotificationDetails';
import './stylesheets/index.scss';
import NotificationFilters, { filtersSchema } from './NotificationFilters';

type NotificationListItemProps = {
    notification: Notification;
    isLastNotification: boolean;
    lastNotificationRef: (node: any) => void;
    selectedNotification: Notification | null;
    setSelectedNotification: Dispatch<
        React.SetStateAction<Notification | null>
    >;
};

const NotificationListItem = ({
    notification,
    isLastNotification,
    lastNotificationRef,
    selectedNotification,
    setSelectedNotification,
}: NotificationListItemProps) => (
    <>
        <li
            ref={(node) => {
                isLastNotification && lastNotificationRef(node);
            }}
            className={classNames('notification-list-item', {
                priority: notification.isPriority,
                read: notification.readDate,
                selected: selectedNotification?.id === notification.id,
            })}
            onClick={() => setSelectedNotification(notification)}
            data-testid="list-item"
        >
            <div className="notification-list-item-info">
                <span className="notification-list-item-subject">
                    {notification.subject}
                </span>
                <span className="notification-list-item-content">
                    {notification.content}
                </span>
            </div>
            <div className="notification-list-item-time">
                <span>
                    {DateTime.fromISO(notification.sentDate).toLocaleString({
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                    })}
                </span>
                <span>
                    {DateTime.fromISO(notification.sentDate).toLocaleString(
                        DateTime.TIME_SIMPLE
                    )}
                </span>
            </div>
        </li>
        <Divider sx={{ opacity: 1 }} />
    </>
);

const PRIMARY_COLOR = '#4E969F';
export const LIST_INCREMENT = 20;

const NotificationsHub = () => {
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [unreadCount, setUnreadCount] = useState<number | null>(null);
    const [selectedNotification, setSelectedNotification] =
        useState<Notification | null>(null);
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [listSize, setListSize] = useState<number>(LIST_INCREMENT);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const methods = useForm<NotificationFilter>({
        resolver: yupResolver(filtersSchema),
    });
    const { getValues: getFilterValues } = methods;

    const { hubConnection } = useNotificationContext();
    const { notificationId: notificationIdFromNavigate } = useLocation().state;
    const { instance } = useMsal();

    const hasMoreNotifications = !isNil(totalCount) && totalCount > listSize;
    const observer = useRef<IntersectionObserver>();
    const lastNotificationRef = useCallback(
        (node) => {
            if (isLoading) {
                return;
            }
            if (observer.current) {
                observer.current.disconnect();
            }
            observer.current = new IntersectionObserver((entries) => {
                if (
                    hasMoreNotifications &&
                    entries[0].isIntersecting &&
                    !isLoading
                ) {
                    setListSize(listSize + LIST_INCREMENT);
                    const filters = getFilterValues();
                    searchNotifications(listSize + LIST_INCREMENT, filters);
                }
            });
            if (node) {
                observer.current.observe(node);
            }
        },
        [isLoading, hasMoreNotifications]
    );

    const searchNotifications = async (
        listSize: number,
        filters?: NotificationFilter
    ) => {
        if (isLoading) {
            return;
        }
        // convert enum key string to enum value
        if (filters?.notificationType) {
            filters.notificationType =
                NotificationType[filters.notificationType];
        }
        setIsLoading(true);
        getToken(instance).then((token) => {
            getUnreadNotificationsCount(token, filters).then((response) => {
                if (response.ok) {
                    response.json().then(({ count }) => {
                        setUnreadCount(count);
                    });
                }
            });
            getNotifications(token, listSize, filters)
                .then((response) => {
                    if (response.ok) {
                        response.json().then(({ notifications, count }) => {
                            // Setting the notifications causes the observed node to be re-rendered
                            // triggering the intersect twice, therefore disconnect the observer beforehand
                            if (observer.current) {
                                observer.current.disconnect();
                            }
                            setNotifications(notifications);
                            setTotalCount(count);
                        });
                    } else {
                        throw new Error();
                    }
                })
                .catch(() =>
                    console.error(
                        'Something went wrong when retreving notifications.'
                    )
                )
                .finally(() => setIsLoading(false));
        });
    };

    const acknowledgeNotification = async (notificationId: string) => {
        const readNotification = await hubConnection?.invoke(
            'AcknowledgeNotification',
            notificationId
        );
        const readNotificationIndex = notifications.findIndex(
            (notification) => notification?.id === readNotification?.id
        );
        if (readNotificationIndex >= 0) {
            notifications[readNotificationIndex] = readNotification;
            getToken(instance).then((token) =>
                getUnreadNotificationsCount(token).then((response) => {
                    if (response.ok) {
                        response.json().then(({ count }) => {
                            setUnreadCount(count);
                        });
                    }
                })
            );
        }
    };

    useEffect(() => {
        searchNotifications(listSize);
    }, []);

    useEffect(() => {
        hubConnection?.on('ReceiveNotification', () => {
            searchNotifications(listSize);
        });
    }, [listSize, hubConnection]);

    useEffect(() => {
        const notificationIdToSelect =
            selectedNotification?.id ?? notificationIdFromNavigate;
        const _selectedNotification = notifications.find(
            (notification) => notification.id === notificationIdToSelect
        );
        if (_selectedNotification) {
            setSelectedNotification(_selectedNotification);
        } else {
            setSelectedNotification(null);
        }
    }, [notifications, notificationIdFromNavigate]);

    useEffect(() => {
        if (!isEmpty(selectedNotification) && !selectedNotification?.readDate) {
            acknowledgeNotification(selectedNotification.id);
        }
    }, [selectedNotification?.id]);

    return (
        <Card>
            <CardContent sx={{ padding: '2rem' }}>
                <div id="notification-hub">
                    <div className="d-flex gap-5 align-items-center flex-wrap">
                        <span id="notification-hub-title" data-testid="title">
                            {`Notifications Hub ${
                                unreadCount ? `(${unreadCount})` : ''
                            }`}
                        </span>
                        <Button
                            variant={showFilters ? 'contained' : 'outlined'}
                            onClick={() => setShowFilters((prev) => !prev)}
                            sx={{
                                display: 'flex',
                                gap: '0.3rem',
                                alignItems: 'center',
                                color: showFilters ? 'white' : PRIMARY_COLOR,
                                backgroundColor: showFilters
                                    ? PRIMARY_COLOR
                                    : 'transparent',
                                borderColor: PRIMARY_COLOR,
                                borderWidth: '2px',
                                minWidth: 'fit-content',
                                '&:hover': {
                                    borderColor: PRIMARY_COLOR,
                                    backgroundColor: showFilters
                                        ? `${PRIMARY_COLOR}cc` // cc = 80% alpha
                                        : 'transparent',
                                    borderWidth: '2px',
                                },
                            }}
                            data-testid="toggle-filters"
                        >
                            <FilterList />
                            Filter
                        </Button>
                    </div>
                    <Collapse
                        in={showFilters}
                        sx={{ minHeight: 'fit-content !important' }}
                        unmountOnExit
                    >
                        <FormProvider {...methods}>
                            <NotificationFilters
                                onSearch={(filters) =>
                                    searchNotifications(listSize, filters)
                                }
                                disableSearch={isLoading}
                            />
                        </FormProvider>
                    </Collapse>
                    <section id="notification-section">
                        {!isEmpty(notifications) ? (
                            <>
                                <ul id="notification-list">
                                    {notifications.map(
                                        (notification, index) => (
                                            <NotificationListItem
                                                key={notification.id}
                                                notification={notification}
                                                isLastNotification={
                                                    index ===
                                                    notifications.length - 1
                                                }
                                                lastNotificationRef={
                                                    lastNotificationRef
                                                }
                                                selectedNotification={
                                                    selectedNotification
                                                }
                                                setSelectedNotification={
                                                    setSelectedNotification
                                                }
                                            />
                                        )
                                    )}
                                    {isLoading && (
                                        <Skeleton
                                            variant="text"
                                            className="notification-list-item"
                                        />
                                    )}
                                </ul>
                                <NotificationDetails
                                    notification={selectedNotification}
                                />
                            </>
                        ) : (
                            <Alert
                                severity="info"
                                sx={{
                                    width: '100%',
                                    padding: '1rem',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                No notifications found.
                            </Alert>
                        )}
                    </section>
                </div>
            </CardContent>
        </Card>
    );
};
export default NotificationsHub;
