import { IPublicClientApplication } from '@azure/msal-browser';
import { getB2CConfig } from 'api/config';

const getToken = async (msalInstance: IPublicClientApplication) => {
    const activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();

    const { scopes } = await getB2CConfig();

    const request = {
        scopes: scopes,
        account: activeAccount || accounts[0],
    };

    const authResult = await msalInstance.acquireTokenSilent(request);
    return authResult.accessToken;
};

export { getToken };
