import React, { ReactNode, useContext } from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { isEmpty } from 'lodash';
import { withAITrackingWithReactPlugin } from '../components/Logging/ApplicationInsights';

const AppInsightContext = React.createContext<ApplicationInsights | null>(null);

type ChildrenHOCProps = {
    children: ReactNode;
};

class ChildrenHOC extends React.Component<ChildrenHOCProps> {
    render() {
        return <>{this.props.children}</>;
    }
}

const ChildrenHOCWithAITracking = withAITrackingWithReactPlugin(ChildrenHOC);

const WithAppInsightContext = ({
    children,
    appInsights,
}: {
    children: ReactNode;
    appInsights: ApplicationInsights;
}) => {
    return (
        <AppInsightContext.Provider value={appInsights}>
            {!isEmpty(appInsights) && (
                <ChildrenHOCWithAITracking>
                    {children}
                </ChildrenHOCWithAITracking>
            )}
        </AppInsightContext.Provider>
    );
};

/*
Please refer to: https://github.com/microsoft/ApplicationInsights-JS#sending-telemetry-to-the-azure-portal
for how to design the trackEvent calls.

Example for .trackEvent():
    const appInsights = useAppInsightContext();
    useEffect(() => {
        appInsights.trackEvent({
            name: 'LayoutLoaded',
            properties: {
                count,
            },
        });
        count++;
    });



*/
const useAppInsightContext = () => {
    const appInsights = useContext(AppInsightContext);
    return appInsights;
};

export { WithAppInsightContext, useAppInsightContext };
