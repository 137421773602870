import { API_ENDPOINT, getAuthHeaders } from './api';

const urlInitiateSingleSignOn = '/sso/initiatesinglesignonpartner';

const getSearchParamsString = (obj) => new URLSearchParams(obj).toString();

const getSingleSignOnUrl = (partnerAlias: string, relayUrl: string) => {
    return `${urlInitiateSingleSignOn}?${getSearchParamsString({
        partnerAlias,
        relayUrl,
    })}`;
};

const getSAMLResponse = async (
    authToken: string,
    partnerAlias: string,
    relayUrl: string
) => {
    const headers = await getAuthHeaders(authToken);

    const response = await fetch(
        `${API_ENDPOINT}${getSingleSignOnUrl(partnerAlias, relayUrl)}`,
        {
            headers,
            method: 'POST',
        }
    );

    return response;
};

export { getSingleSignOnUrl, getSAMLResponse };
