import React from 'react';

import classnames from 'classnames';
import { Button as ButtonBootstrap } from 'react-bootstrap';
import './button.css';

const Button = ({ children, className, ...rest }) => (
    <ButtonBootstrap {...rest} className={classnames('button', className)}>
        {children}
    </ButtonBootstrap>
);

const NextButton = ({ className = '', ...rest }) => (
    <Button {...rest} className={classnames('next', className)} />
);
const BackButton = ({ className = '', ...rest }) => (
    <Button {...rest} className={classnames('back', className)} />
);

export { Button as default, NextButton, BackButton };
