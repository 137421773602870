import { isEmpty, isNil } from 'lodash';
import moment from 'moment';

const API_ENDPOINT = 'api';

const getAuthHeaders = async (authToken: string): Promise<HeadersInit> => {
    const headers: HeadersInit = {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
    };
    return headers;
};

const getLocalTimeHeader = (): HeadersInit => {
    const headers: HeadersInit = {
        'X-LocalTimestamp': moment().format('YYYY-MM-DDTHH:mm:ssZ'),
    };
    return headers;
};

const getSearchParamsString = (obj) => {
    if (isEmpty(obj)) {
        return '';
    }

    const params = new URLSearchParams();
    Object.entries(obj).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value.forEach((v) => {
                params.append(key, v);
            });
        } else if (value instanceof Date) {
            params.append(key, value.toISOString());
        } else if (!isNil(value)) {
            params.append(key, value as string);
        }
    });

    return params.toString();
};

const getFileNameFromResponse = (response: Response) => {
    let filename = '';
    const disposition = response.headers.get('Content-Disposition');
    if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
        }
    }
    return filename;
};

export {
    API_ENDPOINT,
    getAuthHeaders,
    getLocalTimeHeader,
    getSearchParamsString,
    getFileNameFromResponse,
};
