import { isEmpty } from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';
import { useConfigContext } from 'contexts/ConfigProvider';
import { canUserRoute } from './routeMap';
import { useUserContext } from '../../contexts/user';
import { ReactNode } from 'react';

type UserBasedRouteProps = {
    flagId?: string;
    onCheck?: (...args) => void;
    children?: ReactNode;
};

const UserBasedRoute = ({ flagId, onCheck, children }: UserBasedRouteProps) => {
    const { user } = useUserContext();
    const navigate = useNavigate();
    const location = useLocation();
    const { featureFlags } = useConfigContext();
    const isAllowed =
        (isEmpty(flagId) || featureFlags[flagId!]) &&
        (onCheck ? onCheck(user) : true);

    if (!canUserRoute(user, location.pathname, !isAllowed)) {
        navigate('/');
    }

    return <>{children}</>;
};

export default UserBasedRoute;
