import { CertificationDocument } from 'interfaces/CertificationDocument';
import { User } from 'interfaces/User';
import { API_ENDPOINT, getAuthHeaders } from './api';
import { IPublicClientApplication } from '@azure/msal-browser';
import { getToken } from 'Auth';

const USER_PROFILE_UPDATE = '/account/UpdateUserProfile';
const USER_PROFILE_GET = '/account/GetUserProfile';
const USER_CERTIFICATIONS_GET = '/account/GetUserCertifications';
const PRIMARY_AGENTS_GET = '/account/GetPrimaryAgentsByBrokerID';

const updateUserProfile = async (authToken: string, userObject) => {
    const headers = await getAuthHeaders(authToken);
    const url = `${API_ENDPOINT}${USER_PROFILE_UPDATE}`;
    const response = fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(userObject),
    }).then((response) => response.json());
    return response;
};

const getUserProfile = async (msalInstance: IPublicClientApplication) => {
    const authToken = await getToken(msalInstance);
    const headers: HeadersInit = await getAuthHeaders(authToken);
    const url = `${API_ENDPOINT}${USER_PROFILE_GET}`;
    const response = fetch(url, {
        method: 'GET',
        headers,
    }).then(async (response) => {
        if (response.status >= 400 && response.status < 500) {
            msalInstance.logoutRedirect();
            return {} as User;
        }
        return response.json();
    });
    return response;
};

const getUserCertifications = async (
    msalInstance: IPublicClientApplication
) => {
    const authToken = await getToken(msalInstance);
    const headers = await getAuthHeaders(authToken);
    const url = `${API_ENDPOINT}${USER_CERTIFICATIONS_GET}`;
    const response = fetch(url, {
        method: 'GET',
        headers,
    }).then(async (response) => {
        if (response.status >= 400 && response.status < 500) {
            msalInstance.logoutRedirect();
            return {} as CertificationDocument;
        }
        return response.json();
    });
    return response;
};
const getPrimaryAgentsByBrokerId = async (authToken: string) => {
    const headers = await getAuthHeaders(authToken);
    const url = `${API_ENDPOINT}${PRIMARY_AGENTS_GET}`;
    const response = fetch(url, {
        method: 'GET',
        headers,
    }).then((data) => data.json());
    return response;
};
export {
    USER_PROFILE_UPDATE,
    USER_PROFILE_GET,
    updateUserProfile,
    getUserProfile,
    getUserCertifications,
    getPrimaryAgentsByBrokerId,
};
