import React, { useContext, ReactNode, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    title: ({ title }: DialogProps) => ({
        display: 'flex',
        justifyContent: title ? 'space-between' : 'flex-end',
        alignItems: 'center',
        padding: '0.6rem 0.6rem 0.6rem 1.6rem',
        backgroundColor: 'rgb(7, 87, 91)',
        color: 'white',
        width: '100%',

        '& .MuiSvgIcon-root': {
            fill: 'white',
        },
    }),
    content: {
        padding: '1.2rem 1.6rem 1rem 1.6rem !important',
    },
});

interface IDialogContext {
    dialog: DialogProps;
    setDialog: React.Dispatch<React.SetStateAction<DialogProps>>;
    handleClose: () => void;
}

const DialogContext = React.createContext<IDialogContext>({
    dialog: {
        isOpen: false,
    },
    setDialog: () => {},
    handleClose: () => {},
});

const useDialogContext = () => useContext(DialogContext);

interface DialogProviderProps {
    children?: ReactNode;
}

type DialogProps = {
    id?: string;
    isOpen: boolean;
    onClose?: () => void;
    title?: string;
    content?: ReactNode;
    actions?: ReactNode;
    height?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    width?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
};

const DialogProvider = ({ children }: DialogProviderProps) => {
    const [dialog, setDialog] = useState<DialogProps>({
        isOpen: false,
    });

    const theme = useTheme();
    const classes = useStyles(dialog);

    const handleClose = () => {
        if (dialog.onClose) {
            dialog.onClose();
        }
        setDialog({ isOpen: false });
    };

    const contextValues = {
        dialog,
        setDialog,
        handleClose,
    };

    return (
        <DialogContext.Provider value={contextValues}>
            <Dialog
                open={dialog.isOpen}
                onClose={handleClose}
                fullWidth
                maxWidth={dialog.width ?? 'xs'}
                PaperProps={{
                    sx: {
                        ...(dialog.height && {
                            height: theme.breakpoints.values[dialog.height],
                        }),
                    },
                }}
            >
                <DialogTitle className={classes.title}>
                    <span className="text-truncate">{dialog.title}</span>
                    <IconButton
                        onClick={handleClose}
                        data-testid="close-dialog"
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.content}>
                    {dialog.content}
                </DialogContent>
                {dialog.actions && (
                    <DialogActions>{dialog.actions}</DialogActions>
                )}
            </Dialog>
            {children}
        </DialogContext.Provider>
    );
};

export { DialogProvider as default, useDialogContext };
