import { ErrorMessage } from '@hookform/error-message';
import {
    FormControl,
    Popper,
    Autocomplete,
    AutocompleteProps,
    TextField,
} from '@mui/material';
import { useRef } from 'react';
import { Controller } from 'react-hook-form';
import { InputWithControllerProps } from '.';

type AutocompleteWithControllerProps<T> = {
    autocompleteProps: Omit<
        AutocompleteProps<
            T,
            boolean | undefined,
            boolean | undefined,
            boolean | undefined
        >,
        'renderInput'
    >;
    label?: string;
    handleInputChange?: (
        value: any,
        onChangeCallback: (...args) => void
    ) => void;
};

const AutocompleteWithController = <T,>({
    controllerProps,
    autocompleteProps,
    handleChange,
    handleInputChange,
    errorAsPopper,
    label,
}: AutocompleteWithControllerProps<T> & InputWithControllerProps) => {
    const anchorRef = useRef<HTMLDivElement | null>(null);
    return (
        <Controller
            {...controllerProps}
            render={({ field: { value, onChange } }) => {
                return (
                    <FormControl fullWidth size="small" ref={anchorRef}>
                        <Autocomplete
                            {...autocompleteProps}
                            size="small"
                            value={value ?? ''}
                            inputValue={value ?? ''}
                            onChange={(e, value) => {
                                if (handleChange) {
                                    handleChange(value, onChange);
                                } else {
                                    onChange(value);
                                }
                            }}
                            onInputChange={(e, value) => {
                                if (handleInputChange) {
                                    handleInputChange(value, onChange);
                                } else {
                                    onChange(value);
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={label}
                                    variant="outlined"
                                />
                            )}
                        />
                        <ErrorMessage
                            name={controllerProps.name}
                            render={({ message }) =>
                                errorAsPopper ? (
                                    <Popper
                                        open={Boolean(message)}
                                        anchorEl={anchorRef.current}
                                        placement="bottom-start"
                                        disablePortal
                                    >
                                        <span className="p-1 text-danger">
                                            {message}
                                        </span>
                                    </Popper>
                                ) : (
                                    <span className="p-1 text-danger">
                                        {message}
                                    </span>
                                )
                            }
                        />
                    </FormControl>
                );
            }}
        />
    );
};

export default AutocompleteWithController;
