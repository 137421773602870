import React, { ErrorInfo, ReactNode } from 'react';
import {
    ApplicationInsights,
    SeverityLevel,
} from '@microsoft/applicationinsights-web';
import { Alert } from 'react-bootstrap';
import { useAppInsightContext } from '../../contexts/AppInsightsProvider';

const isEnrollmentMissingKeys = (error) => {
    // This only happens when refreshing page. We will ignore it for UI display.
    const errorStr = error.toString();
    const stack = error.stack;
    const stackIsFromEnrollment = stack.toLowerCase().includes('enrollment');
    return (
        errorStr.toLowerCase().includes('cannot read property') &&
        stackIsFromEnrollment
    );
};

type ErrorBoundaryProps = {
    appInsights: ApplicationInsights;
    children: ReactNode;
};

type ErrorBoundaryState = {
    hasError: boolean;
};

class ErrorBoundary extends React.Component<
    ErrorBoundaryProps,
    ErrorBoundaryState
> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        let showError = false;
        showError = showError || !isEnrollmentMissingKeys(error);
        return { hasError: showError };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // You can also log the error to an error reporting service
        const { appInsights } = this.props;
        if (appInsights) {
            appInsights.trackException({
                exception: error,
                severityLevel: SeverityLevel.Error,
            });
        }
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <Alert variant="danger">
                    The application faced an error. Please try again by
                    refreshing the page or contact support.
                </Alert>
            );
        }

        return this.props.children;
    }
}

const ErrorBoundaryWithAppInsights = (props) => {
    const appInsights = useAppInsightContext();
    return <ErrorBoundary {...props} appInsights={appInsights} />;
};

export default ErrorBoundaryWithAppInsights;
