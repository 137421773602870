import { Spinner } from 'react-bootstrap';

const SimpleBox = ({ children }) => (
    <div>
        <div className="row mt-5 justify-content-center align-items-center flex-column">
            {children}
        </div>
    </div>
);
interface LoadingCircleProps {
    label: string;
}
const LoadingCircle = ({ label }: Partial<LoadingCircleProps>) => (
    <SimpleBox>
        <p className="loading-label">{label}</p>
        <Spinner className="spinner" />
    </SimpleBox>
);
export default LoadingCircle;
