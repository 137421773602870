import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { ArrowForwardIos, Close } from '@mui/icons-material';
import { IconButton, Skeleton } from '@mui/material';
import { isEmpty, range } from 'lodash';
import { useNavigate } from 'react-router';
import { getNotifications } from 'api/notifications';
import { getToken } from 'Auth';
import { useNotificationContext } from 'contexts/NotificationsProvider';
import './stylesheets/index.scss';
import Notification from 'interfaces/Notification';
import NotificationCard from './NotificationCard';

export const MAX_NOTIFICATION_COUNT = 5;

export type NotificationsMenuProps = {
    onClose: () => void;
};

const NotificationsMenu = ({ onClose }: NotificationsMenuProps) => {
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { hubConnection } = useNotificationContext();
    const navigate = useNavigate();
    const { instance } = useMsal();

    const navigateToHub = (notificationId?: string) => () => {
        navigate('/notifications', { state: { notificationId } });
        onClose();
    };

    const searchNotifications = async () => {
        setIsLoading(true);
        getToken(instance).then((token) =>
            getNotifications(token, MAX_NOTIFICATION_COUNT)
                .then((response) => {
                    if (response.ok) {
                        response.json().then(({ notifications }) => {
                            setNotifications(notifications);
                        });
                    } else {
                        throw new Error();
                    }
                })
                .catch(() =>
                    console.error(
                        'Something went wrong when retrieving notifications.'
                    )
                )
                .finally(() => setIsLoading(false))
        );
    };

    useEffect(() => {
        searchNotifications();
    }, []);

    useEffect(() => {
        hubConnection?.on('ReceiveNotification', () => {
            searchNotifications();
        });
    }, [hubConnection]);

    return (
        <div id="notifications-menu">
            <div className="d-flex justify-content-between align-items-center ps-2 w-100">
                <span id="notifications-title">Notifications</span>
                <IconButton onClick={onClose} size="large">
                    <Close fontSize="inherit" sx={{ fill: 'white' }} />
                </IconButton>
            </div>
            <ul className="d-flex flex-column gap-3 w-100">
                {isLoading ? (
                    range(MAX_NOTIFICATION_COUNT).map((_, index) => (
                        <Skeleton
                            key={index}
                            variant="rounded"
                            width="100%"
                            height="8rem"
                            data-testid={`skeleton`}
                        />
                    ))
                ) : !isEmpty(notifications) ? (
                    notifications
                        ?.slice(0, MAX_NOTIFICATION_COUNT)
                        .map((notification) => (
                            <li
                                key={notification.id}
                                className="position-relative"
                                onClick={navigateToHub(notification.id)}
                                style={{ cursor: 'pointer' }}
                                data-testid={`notification-card-${notification.id}`}
                            >
                                <NotificationCard notification={notification} />
                            </li>
                        ))
                ) : (
                    <></>
                )}
            </ul>
            {!isLoading && isEmpty(notifications) && (
                <div
                    className="d-flex flex-column p-3 w-100 align-items-center text-light border border-white rounded-1"
                    data-testid="no-notifications"
                >
                    <span>No notifications found.</span>
                </div>
            )}
            <button
                id="view-more"
                onClick={navigateToHub()}
                data-testid="view-more"
            >
                <span>View All</span>
                <ArrowForwardIos fontSize="inherit" />
            </button>
        </div>
    );
};

export default NotificationsMenu;
