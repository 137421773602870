import {
    ReactPlugin,
    withAITracking,
} from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const reactPlugin = new ReactPlugin();
const withAITrackingWithReactPlugin = (component) =>
    withAITracking(reactPlugin, component);

let appInsights;

function initAppInsights(instrumentationKey) {
    appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: instrumentationKey,
            extensions: [reactPlugin],
            enableAutoRouteTracking: true,
        },
    });

    appInsights.loadAppInsights();
    return appInsights;
}

export { initAppInsights, reactPlugin, withAITrackingWithReactPlugin };
