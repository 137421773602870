import { lazy } from 'react';
import { createTheme } from '@mui/material';
import {
    Theme,
    ThemeProvider,
    StyledEngineProvider,
} from '@mui/material/styles';
import CacheBuster from 'react-cache-buster';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider,
} from 'react-router-dom';
import SSOHandler from 'components/Common/SSOHandler';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import Layout from 'components/Layout';
import { FEATURE_IDS } from 'constants/identifiers';
import UserBasedRoute from './components/Routing/ConditionalRoute';
import { WithUserContext } from './contexts/user';
import packageInfo from '../package.json';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/animate.min.css';
import './assets/css/demo.css';
import './assets/css/enrollment.scss';
import './assets/css/pe-icon-7-stroke.css';
import './custom.scss';
import './syncfusion.css';
import './contexts/config.css';
import './assets/css/avabroker.scss';
import NotificationsHub from 'components/Notifications/Hub';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import NotificationsProvider from 'contexts/NotificationsProvider';

declare module '@mui/styles' {
    interface DefaultTheme extends Theme {}
}

const version = packageInfo.version;

const Dashboard = lazy(() => import('./components/Dashboard/Dashboard'));
const SubmitApplicationWithReactHookForms = lazy(
    () => import('./components/EnrollmentV2')
);
const MyClients = lazy(() => import('./components/MyClients'));
const Certification = lazy(
    () => import('./components/Certification/Certification')
);
const FormsAndDocumentsExplorer = lazy(() =>
    import('./components/FormsDocuments/FormsAndDocumentsExplorer').then(
        (module) => {
            return { default: module.FormsAndDocumentsExplorer };
        }
    )
);
const UserPage = lazy(() => import('./components/UserProfile/UserProfile'));
const HelpfulTools = lazy(() => import('components/HelpfulTools/HelpfulTools'));
const FrequentlyAskedQuestions = lazy(
    () => import('./components/HelpfulTools/Faq')
);
const ContactUs = lazy(() => import('./components/HelpfulTools/ContactUs'));

const Commissions = lazy(() => import('./components/Commissions'));

const routes = (
    <Route element={<Layout />} errorElement={<ErrorBoundary />}>
        <Route
            index
            element={
                <UserBasedRoute>
                    <Dashboard />
                </UserBasedRoute>
            }
        />
        <Route
            path="submit-application"
            element={
                <UserBasedRoute>
                    <SubmitApplicationWithReactHookForms />
                </UserBasedRoute>
            }
        />
        <Route
            path="/my-clients"
            element={
                <UserBasedRoute>
                    <MyClients />
                </UserBasedRoute>
            }
        />
        <Route
            path="/commissions"
            element={
                <UserBasedRoute onCheck={(user) => user?.allowDirectPay}>
                    <Commissions />
                </UserBasedRoute>
            }
        />
        <Route
            path="/forms-and-documents"
            element={
                <UserBasedRoute>
                    <FormsAndDocumentsExplorer />
                </UserBasedRoute>
            }
        />
        <Route
            path="/certification"
            element={
                <UserBasedRoute>
                    <Certification />
                </UserBasedRoute>
            }
        />
        <Route
            path="/helpful-tools"
            element={
                <UserBasedRoute>
                    <HelpfulTools />
                </UserBasedRoute>
            }
        />
        <Route
            path="/faq"
            element={
                <UserBasedRoute>
                    <FrequentlyAskedQuestions />
                </UserBasedRoute>
            }
        />
        <Route
            path="/contact-us"
            element={
                <UserBasedRoute>
                    <ContactUs />
                </UserBasedRoute>
            }
        />
        <Route
            path="/user-profile"
            element={
                <UserBasedRoute>
                    <UserPage />
                </UserBasedRoute>
            }
        />
        <Route
            path="/sso"
            element={
                <UserBasedRoute onCheck={(user) => user?.allowDirectPay}>
                    <SSOHandler />
                </UserBasedRoute>
            }
        />
        <Route
            path="/notifications"
            element={
                <UserBasedRoute flagId={FEATURE_IDS.NOTIFICATIONS}>
                    <NotificationsHub />
                </UserBasedRoute>
            }
        />
    </Route>
);

const router = createBrowserRouter(createRoutesFromElements(routes));

const App = () => {
    const isProduction = process.env.NODE_ENV === 'production';
    const theme = createTheme();

    return (
        <CacheBuster
            currentVersion={version}
            isEnabled={isProduction} //If false, the library is disabled.
            isVerboseMode={false} //If true, the library writes verbose logs to console.
        >
            <WithUserContext>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <NotificationsProvider>
                                <RouterProvider router={router} />
                            </NotificationsProvider>
                        </LocalizationProvider>
                    </ThemeProvider>
                </StyledEngineProvider>
            </WithUserContext>
        </CacheBuster>
    );
};

export default App;
