const USER_TYPE = {
    FieldAgent: 'FieldAgent',
    TeleAgent: 'TeleAgent',
    Broker: 'Broker',
};
const RESOURCE_TYPE = {
    PLAN_MATERIALS: 'RequestPlanMaterials',
    PROVIDER_SEARCH: 'ProviderSearch',
    DRUG_SEARCH: 'DrugSearch',
    CERTIFICATION: 'Certification',
    AHIP_YEARS: 'AhipYears',
    BUSINESS_STATES: 'BusinessStates',
    LICENSE_PRE_AEP: 'LicensingPreAEP',
    LICENSE_AEP: 'LicensingAEP',
};
const RESPONSE = {
    YES: 'YES',
    NO: 'NO',
};
const GENDER_CODE = {
    FEMALE: 'F',
    MALE: 'M',
};
const APPLICATIONS_VIEW = {
    ALL: { status: '', timeline: '' },
    ACCEPTED_YTD: { status: 'Accepted', timeline: 'YTD' },
    ACCEPTED_MTD: { status: 'Accepted', timeline: 'MTD' },
    SUBMITTED_YTD: { status: 'Submitted', timeline: 'YTD' },
    SUBMITTED_MTD: { status: 'Submitted', timeline: 'MTD' },
};
const FEATURE_IDS = {
    ENROLL_INSIGHTS: 'agent-enrollment-insights',
    NOTIFICATIONS: 'notifications',
    VERIFY_CMS: 'verify-cms',
    FILE_UPLOAD_PREVIEW: 'file-upload-preview',
    SNP_QUALIFICATION_CONRIMATION: 'snp-qualification-confirmation',
    PCP_LOCATION_SELECTION: 'pcp-location-selection',
    ADDRESS_AUTOCOMPLETE: 'address-autocomplete',
};

const ASSESSMENT_STATUS = {
    INVALID: 'Invalid',
    NOT_STARTED: 'NotStarted',
    COMPLETED: 'Completed',
    SUBMITTED: 'Submitted',
};

const NULL_STRING = 'NULL';

export {
    USER_TYPE,
    RESPONSE,
    GENDER_CODE,
    RESOURCE_TYPE,
    APPLICATIONS_VIEW,
    FEATURE_IDS,
    ASSESSMENT_STATUS,
    NULL_STRING,
};
