import { Close, RestartAlt, Search } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { useFormContext } from 'react-hook-form';
import { date, object } from 'yup';
import MuiButton from 'components/Common/Button/MuiButton';
import {
    AutocompleteWithController,
    CheckboxWithController,
    DatePickerWithController,
    TextFieldWithController,
} from 'components/Common/Form/MuiInputs';
import NotificationType from 'enums/Notifications/NotificationType';
import NotificationFilter from 'interfaces/NotificationFilter';
import './stylesheets/NotificationFilters.scss';

const filtersSchema = object().shape(
    {
        fromSentDate: date().when('toSentDate', ([value], schema) =>
            DateTime.fromJSDate(value).isValid
                ? schema.test(
                      'is-before-to-date',
                      'From Date should be before To Date',
                      (value, context) =>
                          value ? value < context.parent.toSentDate : true
                  )
                : schema
        ),
        toSentDate: date().when('fromSentDate', ([value], schema) =>
            DateTime.fromJSDate(value).isValid
                ? schema.test(
                      'is-after-from-date',
                      'To Date should be after From Date',
                      (value, context) =>
                          value ? value > context.parent.fromSentDate : true
                  )
                : schema
        ),
    },
    [
        ['fromSentDate', 'toSentDate'],
        ['toSentDate', 'fromSentDate'],
    ]
);

export type NotificationFiltersProps = {
    onSearch: (filters: NotificationFilter) => Promise<void>;
    disableSearch?: boolean;
};

const NotificationFilters = ({
    onSearch,
    disableSearch = false,
}: NotificationFiltersProps) => {
    const { handleSubmit, reset, watch, setValue } = useFormContext();

    const searchTerm = watch('searchTerm');

    return (
        <form onSubmit={handleSubmit(onSearch)}>
            <div id="notification-filters" data-testid="filters">
                <div
                    className="notification-filters-input"
                    data-testid="fromSentDate"
                >
                    <DatePickerWithController
                        controllerProps={{ name: 'fromSentDate' }}
                        datePickerProps={{
                            label: 'From Sent Date',
                            views: ['year', 'month', 'day'],
                            timezone: 'UTC',
                        }}
                        errorAsPopper
                    />
                </div>
                <div
                    className="notification-filters-input"
                    data-testid="toSentDate"
                >
                    <DatePickerWithController
                        controllerProps={{ name: 'toSentDate' }}
                        datePickerProps={{
                            label: 'To Sent Date',
                            views: ['year', 'month', 'day'],
                            timezone: 'UTC',
                        }}
                        errorAsPopper
                    />
                </div>
                <div
                    className="notification-filters-input"
                    data-testid="notificationType"
                >
                    <AutocompleteWithController
                        controllerProps={{ name: 'notificationType' }}
                        label="Notification Type"
                        autocompleteProps={{
                            options: Object.values(NotificationType).filter(
                                (value) => isNaN(Number(value))
                            ),
                        }}
                    />
                </div>
                <div className="notification-filters-input">
                    <TextFieldWithController
                        controllerProps={{ name: 'searchTerm' }}
                        textFieldProps={{
                            label: 'Search Term',
                            inputProps: { 'data-testid': 'searchTerm' },
                            InputProps: {
                                endAdornment: (
                                    <IconButton
                                        onClick={() =>
                                            setValue('searchTerm', undefined)
                                        }
                                        sx={{
                                            visibility: !isEmpty(searchTerm)
                                                ? 'visible'
                                                : 'hidden',
                                        }}
                                    >
                                        <Close />
                                    </IconButton>
                                ),
                                sx: { paddingRight: 0 },
                            },
                        }}
                    />
                </div>
                <div data-testid="notificationType">
                    <CheckboxWithController
                        controllerProps={{ name: 'isPriority' }}
                        labelProps={{
                            label: 'Priority',
                            sx: { fontSize: '0.9rem' },
                        }}
                    />
                </div>
                <div className="d-flex gap-3">
                    <MuiButton
                        variant="contained"
                        onClick={() => reset()}
                        sx={{ gap: '0.4rem' }}
                        data-testid="reset"
                    >
                        <RestartAlt sx={{ color: 'white' }} />
                        <span>Reset</span>
                    </MuiButton>
                    <MuiButton
                        variant="contained"
                        type="submit"
                        sx={{ gap: '0.4rem' }}
                        disabled={disableSearch}
                        data-testid="search"
                    >
                        <Search sx={{ color: 'white' }} />
                        <span>Search</span>
                    </MuiButton>
                </div>
            </div>
        </form>
    );
};
export { NotificationFilters as default, filtersSchema };
