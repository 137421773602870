import { Suspense, useCallback, useEffect, useState } from 'react';
import { Drawer, Slide, Snackbar } from '@mui/material';
import { isEmpty } from 'lodash';
import { Row, Col, Container } from 'react-bootstrap';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import LoadingCircle from 'components/Common/Loading';
import Footer from 'components/Layout/Footer';
import Header from 'components/NavMenu/Header';
import Menu from 'components/NavMenu/Menu';
import 'components/NavMenu/NavMenu.scss';
import './index.scss';
import NotificationsMenu from 'components/Notifications/Menu';
import NotificationCard from 'components/Notifications/Menu/NotificationCard';
import DialogProvider from 'contexts/DialogProvider';
import { useNotificationContext } from 'contexts/NotificationsProvider';
import Notification from 'interfaces/Notification';

const LoadingTemplate = () => (
    <div id="loading-template">
        <LoadingCircle />
    </div>
);

const SlideTransition = (props) => <Slide {...props} direction="up" />;

const SNACKBAR_DURATION = 5000;

const Layout = () => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const [showDrawer, setShowDrawer] = useState<boolean>(false);
    const [notification, setNotification] = useState<Notification | null>(null);
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
    const [timeoutHandle, setTimeoutHandle] = useState<NodeJS.Timeout | null>(
        null
    );
    const handleToggle = useCallback((updatedExpandedFlag: boolean) => {
        setExpanded(updatedExpandedFlag);
    }, []);

    const { hubConnection } = useNotificationContext();

    const toggleDrawer = () => {
        setShowDrawer(!showDrawer);
    };

    const closeSnackbar = () => {
        setShowSnackbar(false);

        // The timeout has additional time to allow the timeout animation to finish
        if (timeoutHandle) {
            clearTimeout(timeoutHandle);
        }
        setTimeoutHandle(
            setTimeout(() => setNotification(null), SNACKBAR_DURATION + 3000)
        );
    };

    useEffect(() => {
        hubConnection?.on('ReceiveNotification', (notification) => {
            setNotification(notification);
        });
    }, [hubConnection]);

    useEffect(() => {
        if (!isEmpty(notification)) {
            setShowSnackbar(true);
        }
    }, [notification]);

    return (
        <DialogProvider>
            <ScrollRestoration />
            <Container fluid className="layout-wrapper">
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={showSnackbar}
                    onClose={closeSnackbar}
                    autoHideDuration={SNACKBAR_DURATION}
                    TransitionComponent={SlideTransition}
                    ClickAwayListenerProps={{ onClickAway: () => null }}
                >
                    <div style={{ width: '400px' }}>
                        <NotificationCard
                            notification={notification}
                            isSnackbar
                            onClose={closeSnackbar}
                        />
                    </div>
                </Snackbar>
                <div>
                    <Drawer
                        anchor="right"
                        open={showDrawer}
                        onClose={toggleDrawer}
                        PaperProps={{
                            sx: {
                                backgroundColor: 'transparent',
                            },
                        }}
                    >
                        <NotificationsMenu onClose={toggleDrawer} />
                    </Drawer>
                    <Row role="banner" aria-label="Main Header">
                        <Col>
                            <div className="shadow">
                                <Header
                                    handleToggle={handleToggle}
                                    onNotificationIconClick={toggleDrawer}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row role="navigation" aria-label="Main Navigation">
                        <Col>
                            <div className="shadow">
                                <Menu expanded={expanded} />
                            </div>
                        </Col>
                    </Row>
                    <Row role="main">
                        <Col>
                            <div id="main-portal-content"></div>
                            <Suspense fallback={<LoadingTemplate />}>
                                <Outlet />
                            </Suspense>
                        </Col>
                    </Row>
                </div>
                <Footer />
            </Container>
        </DialogProvider>
    );
};

export default Layout;
