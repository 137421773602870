export const APPLICATION_STATUS = {
    SUBMITTED: 'Submitted',
    REJECTED: 'Rejected',
    ENROLLED: 'Enrolled',
    PENDING: 'Pending',
    ACCEPTED: 'Accepted',
};

export const VIEW_STATE = {
    TABLE: 0,
    DETAILS: 1,
    HRA_FORM: 2,
};
