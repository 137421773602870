import {
    Checkbox,
    FormControlLabel,
    FormControlLabelProps,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { InputWithControllerProps } from '.';

type CheckboxWithControllerProp = {
    labelProps: Omit<FormControlLabelProps, 'control'>;
};

const CheckboxWithController = ({
    controllerProps,
    labelProps,
    handleChange,
}: CheckboxWithControllerProp & InputWithControllerProps) => (
    <Controller
        {...controllerProps}
        render={({ field: { value, onChange } }) => (
            <FormControlLabel
                {...labelProps}
                label={labelProps?.label}
                control={
                    <Checkbox
                        checked={value ? true : false}
                        onChange={(e) => {
                            if (handleChange) {
                                handleChange(e.target.checked, onChange);
                            } else {
                                onChange(e.target.checked);
                            }
                        }}
                    />
                }
                sx={{ width: 'fit-content' }}
            />
        )}
    />
);
export default CheckboxWithController;
