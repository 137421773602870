import { ErrorMessage } from '@hookform/error-message';
import { FormControl, Popper, TextField, TextFieldProps } from '@mui/material';
import { useRef } from 'react';
import { Controller } from 'react-hook-form';
import { InputWithControllerProps } from '.';

type TextFieldWithControllerProps = {
    textFieldProps: TextFieldProps;
};

const TextFieldWithController = ({
    controllerProps,
    textFieldProps,
    handleChange,
    errorAsPopper,
}: TextFieldWithControllerProps & InputWithControllerProps) => {
    const anchorRef = useRef<HTMLDivElement | null>(null);
    return (
        <Controller
            {...controllerProps}
            render={({ field: { value, onChange } }) => (
                <FormControl fullWidth ref={anchorRef}>
                    <TextField
                        {...textFieldProps}
                        value={value ?? ''}
                        onChange={(e) => {
                            if (handleChange) {
                                handleChange(e.target.value, onChange);
                            } else {
                                onChange(e.target.value);
                            }
                        }}
                        size="small"
                    />
                    <ErrorMessage
                        name={controllerProps.name}
                        render={({ message }) =>
                            errorAsPopper ? (
                                <Popper
                                    open={Boolean(message)}
                                    anchorEl={anchorRef.current}
                                    placement="bottom-start"
                                    disablePortal
                                >
                                    <span className="p-1 text-danger">
                                        {message}
                                    </span>
                                </Popper>
                            ) : (
                                <span className="p-1 text-danger">
                                    {message}
                                </span>
                            )
                        }
                    />
                </FormControl>
            )}
        />
    );
};

export default TextFieldWithController;
