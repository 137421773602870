import { registerLicense } from '@syncfusion/ej2-base';
import { createRoot } from 'react-dom/client';
import App from './App';
import { initAppInsights } from './components/Logging/ApplicationInsights';
import { WithAppInsightContext } from './contexts/AppInsightsProvider';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import { ConfigProvider, useConfigContext } from 'contexts/ConfigProvider';
import AuthProvider from 'contexts/AuthProvider';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement as HTMLElement);

const AppInsightsWrapper = ({ children }) => {
    const { configValues } = useConfigContext();
    const { syncfusionLicenseKey, instrumentationKey } = configValues;
    registerLicense(syncfusionLicenseKey!);
    const appInsights = initAppInsights(instrumentationKey);

    return (
        <WithAppInsightContext appInsights={appInsights}>
            {children}
        </WithAppInsightContext>
    );
};

root.render(
    <ConfigProvider>
        <AppInsightsWrapper>
            <AuthProvider>
                <App />
            </AuthProvider>
        </AppInsightsWrapper>
    </ConfigProvider>
);

unregisterServiceWorker();
