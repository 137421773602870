import React, { useEffect , useState } from 'react';

import { getSAMLResponse } from 'api/SSO';
import LoadingCircle from './Loading';
import { getToken } from 'Auth';
import { useMsal } from '@azure/msal-react';

const SSOHandler = () => {
    const [__html, setHtml] = useState();
    const { instance } = useMsal();
    const urlParams = new URLSearchParams(window.location.search);

    const partnerName = urlParams.get('partnerName');
    const relayUrl = urlParams.get('relayUrl');

    useEffect(() => {
        getToken(instance)
            .then((token) => getSAMLResponse(token, partnerName, relayUrl))
            .then((response) => response.text())
            .then((text) => setHtml(text));
    }, []);

    useEffect(() => {
        document.forms.samlform && document.forms.samlform.submit();
    });

    return (
        <>
            <div id="loading-template">
                <LoadingCircle />
            </div>
            <div dangerouslySetInnerHTML={{ __html }} />
        </>
    );
};

export default SSOHandler;
