import { ErrorMessage } from '@hookform/error-message';
import { FormControl, Popper } from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { useRef } from 'react';
import { Controller } from 'react-hook-form';
import { InputWithControllerProps } from '.';

type DatePickerWithControllerProps = {
    datePickerProps: DatePickerProps<DateTime>;
};


/**
 * react-hook-form wrapper for a DatePicker. The value is expected to be an ISO string due to react-hook-form's inability
 * to properly handle DateTime objects.
 */
const DatePickerWithController = ({
    controllerProps,
    datePickerProps,
    handleChange,
    errorAsPopper,
}: DatePickerWithControllerProps & InputWithControllerProps) => {
    const anchorRef = useRef<HTMLDivElement | null>(null);

    return (
        <Controller
            {...controllerProps}
            render={({ field: { value, onChange } }) => (
                <FormControl fullWidth ref={anchorRef}>
                    <DatePicker
                        value={value ? DateTime.fromISO(value) : null}
                        onChange={(value) => {
                            if (handleChange) {
                                handleChange(value?.toISO(), onChange);
                            } else {
                                onChange(value?.toISO());
                            }
                        }}
                        slotProps={{
                            textField: {
                                size: 'small',
                                fullWidth: true,
                            },
                        }}
                        {...datePickerProps}
                    />
                    <ErrorMessage
                        name={controllerProps.name}
                        render={({ message }) =>
                            errorAsPopper ? (
                                <Popper
                                    open={Boolean(message)}
                                    anchorEl={anchorRef.current}
                                    placement="bottom-start"
                                    disablePortal
                                >
                                    <span className="p-1 text-danger">
                                        {message}
                                    </span>
                                </Popper>
                            ) : (
                                <span className="p-1 text-danger">
                                    {message}
                                </span>
                            )
                        }
                    />
                </FormControl>
            )}
        />
    );
};

export default DatePickerWithController;
