import {
    Configuration,
    InteractionType,
    PublicClientApplication,
    RedirectRequest,
} from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import LoadingCircle from 'components/Common/Loading';
import React, { useContext, useEffect, useState } from 'react';
import { useConfigContext } from './ConfigProvider';

const AuthProvider = ({ children }) => {
    const [msalInstance, setMsalInstance] = useState<PublicClientApplication>();
    const { configValues } = useConfigContext();
    const {
        clientId,
        authority,
        knownAuthorities,
        redirectUri,
        postLogoutRedirectUri,
        cacheLocation,
        scopes,
    } = configValues;
    const configuration: Configuration = {
        auth: {
            clientId: clientId!,
            authority,
            knownAuthorities,
            redirectUri,
            postLogoutRedirectUri,
        },
        cache: {
            cacheLocation,
        },
    };

    const redirectRequest: RedirectRequest = {
        scopes: scopes ?? [],
    };

    const setupAuth = async () => {
        const _pca = new PublicClientApplication(configuration);
        await _pca.initialize();
        setMsalInstance(_pca);
    };

    useEffect(() => {
        setupAuth();
    }, [configValues]);

    if (!msalInstance) {
        return <LoadingCircle />;
    }

    return (
        <MsalProvider instance={msalInstance}>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={redirectRequest}
            >
                {children}
            </MsalAuthenticationTemplate>
        </MsalProvider>
    );
};

export default AuthProvider;
