import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/styles';

const BG_COLOR_RGB = '7, 87, 91';

const StyledButton = styled(Button)(
    ({ variant, isClicked, disabled }: MuiButtonProps & ButtonProps) => ({
        padding: '0.4rem 1.2rem',
        border: `2px solid rgb(${BG_COLOR_RGB})`,
        textTransform: 'none',
        ...(variant === 'outlined'
            ? {
                  ...(isClicked
                      ? { background: `rgb(${BG_COLOR_RGB})`, color: 'white' }
                      : {
                            color: `rgb(${BG_COLOR_RGB})`,
                        }),

                  '&:hover': {
                      background: `rgb(${BG_COLOR_RGB})`,
                      color: 'white',
                  },
              }
            : {
                  background: `rgb(${BG_COLOR_RGB})`,
                  color: 'white',

                  '&:hover': {
                      background: 'rgb(56, 121, 124)',
                      borderColor: 'rgb(56, 121, 124)',
                  },
              }),
        ...(disabled && {
            background: `rgba(${BG_COLOR_RGB}, 0.6)`,
            borderColor: 'transparent',
            '&.Mui-disabled': { color: 'white' },
        }),

        '& .MuiButton-label': {
            display: 'flex',
            alignItems: 'center',
            gap: '0.4rem',
        },
    })
);

type MuiButtonProps = {
    isClicked?: boolean;
};

const MuiButton = ({ children, ...rest }: MuiButtonProps & ButtonProps) => {
    return <StyledButton {...rest}>{children}</StyledButton>;
};

export default MuiButton;
