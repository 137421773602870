import React, {
    useContext,
    useState,
    useEffect,
    useCallback,
    useMemo,
} from 'react';
import { isEmpty, get } from 'lodash';
import { Spinner } from 'react-bootstrap';
import { CertificationDocument } from 'interfaces/CertificationDocument';
import { User } from 'interfaces/User';
import { getUserProfile, getUserCertifications } from '../api/user';
import { USER_TYPE } from '../constants/identifiers';
import './config.css';
import { useMsal } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';

interface UserContextInterface {
    user: User;
    reloadUser: () => void | Promise<void>;
    certifications: CertificationDocument[];
    reloadCertifications: () => void | Promise<void>;
}

const UserContext = React.createContext<UserContextInterface>({
    user: {} as User,
    reloadUser: () => {
        return undefined;
    },
    certifications: [],
    reloadCertifications: () => {
        return undefined;
    },
});

const checkAuthorizedUser = async (
    userObj: User,
    instance: IPublicClientApplication
): Promise<boolean> => {
    const { userId, userType } = userObj;
    if (!(userId && get(USER_TYPE, userType as string))) {
        instance.logoutRedirect();
        return false;
    }
    return true;
};

const WithUserContext = ({ children, ...rest }) => {
    const [user, setUser] = useState<User>({} as User);
    const [certifications, setCertifications] = useState<
        CertificationDocument[]
    >([]);

    const { instance } = useMsal();

    const reloadUser = useCallback(async () => {
        const newUser: User = await getUserProfile(instance);
        if (await checkAuthorizedUser(newUser, instance)) {
            setUser(newUser);
        }
    }, [setUser]);

    const reloadCertifications = useCallback(async () => {
        const newCerts: CertificationDocument[] = await getUserCertifications(
            instance
        );
        setCertifications(newCerts);
    }, [setCertifications]);

    useEffect(() => {
        const processUserService = getUserProfile(instance)
            .then(async (newUser) => {
                if (await checkAuthorizedUser(newUser, instance)) {
                    setUser(newUser);
                }
            })
            .catch(async () => {
                instance.logoutRedirect();
            });
        const processCertificationService = getUserCertifications(
            instance
        ).then((certList) => {
            setCertifications(certList);
        });
        Promise.all([processUserService, processCertificationService]);
    }, []);

    const contextValues = useMemo(
        () => ({
            user,
            reloadUser,
            certifications,
            reloadCertifications,
        }),
        [user, certifications]
    );

    if (isEmpty(user)) {
        return (
            <div className="pre-loading">
                <Spinner variant="secondary" />
            </div>
        );
    }

    return (
        <UserContext.Provider {...rest} value={contextValues}>
            {!isEmpty(user) && children}
        </UserContext.Provider>
    );
};

const useUserContext = () => {
    return useContext(UserContext);
};

const withUserContextHOC =
    (Component) =>
    ({ children, ...rest }) => {
        const { user, reloadUser, certifications, reloadCertifications } =
            useUserContext();

        return (
            <Component
                {...rest}
                user={user}
                reloadUser={reloadUser}
                certifications={certifications}
                reloadCertifications={reloadCertifications}
            >
                {children}
            </Component>
        );
    };

export { WithUserContext, useUserContext, withUserContextHOC, UserContext };
