import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { get, isEmpty } from 'lodash';
import { Nav, Navbar, NavItem, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { USER_TYPE } from 'constants/identifiers';
import checkExpanded from './util/checkExpanded';
import { withUserContextHOC, useUserContext } from '../../contexts/user';
import { canUserRoute } from '../Routing/routeMap';
import './NavMenu.scss';

const tooltipMessages = {
    blockedForAgency: 'Agencies are not able to access this menu.',
};
const PAGE_TITLES_WIHOUT_HEADERS = ['HOME', 'MY CLIENTS', 'FORMS & DOCUMENTS'];

const isUserBroker = ({ userType }) => userType === USER_TYPE.Broker;

const WithTooltip = ({ show, message, children }) => {
    if (!show) {
        return children;
    }
    return (
        <OverlayTrigger
            delay={500}
            key={'bottom'}
            placement={'bottom'}
            overlay={<Tooltip show={show}>{message}</Tooltip>}
        >
            {children}
        </OverlayTrigger>
    );
};

const MenuItem = withUserContextHOC(({ to, exact, eventKey, text, user }) => {
    const location = useLocation();
    const pathname = get(location, 'pathname');
    const disabled = isEmpty(user) || !canUserRoute(user, to);
    return (
        <WithTooltip show={disabled} message={tooltipMessages.blockedForAgency}>
            <NavItem style={{ flex: 1 }}>
                <Nav.Link
                    as={Link}
                    exact={exact}
                    to={to}
                    eventKey={eventKey}
                    active={pathname === to}
                    disabled={disabled}
                >
                    <span
                        role={
                            pathname === to &&
                            PAGE_TITLES_WIHOUT_HEADERS.includes(text)
                                ? 'heading'
                                : null
                        }
                        aria-level={
                            pathname === to &&
                            PAGE_TITLES_WIHOUT_HEADERS.includes(text)
                                ? '1'
                                : null
                        }
                    >
                        {text}
                    </span>
                </Nav.Link>
            </NavItem>
        </WithTooltip>
    );
});

const Menu = ({ expanded: initExpanded }) => {
    const { user } = useUserContext();

    const userIsBroker = isUserBroker(user);
    const isCommissionsEnabled = user?.allowDirectPay;

    const [expanded, setExpanded] = useState(initExpanded);
    useEffect(() => {
        if (!checkExpanded('page-navigation') && initExpanded) {
            setExpanded(initExpanded);
        } else {
            setExpanded(undefined);
        }
    }, [initExpanded]);

    return (
        <div className={classnames('menu', { hidden: !initExpanded })}>
            <Navbar
                id="page-navigation"
                collapseOnSelect
                expand="lg"
                expanded={expanded}
                aria-label="Primary Navigation"
            >
                <Navbar.Collapse
                    id="responsive-navbar-nav-menu"
                    className="navbar-toggle"
                >
                    <Nav
                        defaultActiveKey="home"
                        style={{
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <MenuItem
                            eventKey="home"
                            exact={'true'}
                            to="/"
                            text="HOME"
                        />
                        <MenuItem
                            eventKey="enrollment"
                            to="/submit-application"
                            text="SUBMIT ENROLLMENT"
                        />

                        <MenuItem
                            eventKey="clients"
                            to="/my-clients"
                            text={userIsBroker ? 'MY AGENTS' : 'MY CLIENTS'}
                        />
                        {isCommissionsEnabled && (
                            <MenuItem
                                eventKey="commissions"
                                to="/commissions"
                                text="MY COMMISSIONS"
                            />
                        )}
                        <MenuItem
                            eventKey="forms"
                            to="/forms-and-documents"
                            text="FORMS & DOCUMENTS"
                        />
                        <MenuItem
                            eventKey="certification"
                            to="/certification"
                            text="CERTIFICATION"
                        />
                        <MenuItem
                            eventKey="helpful-tools"
                            to="/helpful-tools"
                            text="HELPFUL TOOLS"
                        />
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
};

export default Menu;
