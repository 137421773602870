import NotificationFilter from 'interfaces/NotificationFilter';
import { getAuthHeaders, getSearchParamsString } from './api';

const getNotifications = async (
    authToken: string,
    take: number,
    filters: NotificationFilter = {}
) => {
    const headers = await getAuthHeaders(authToken);
    return fetch(
        `api/Notifications/GetNotifications?${getSearchParamsString({
            ...filters,
            take,
        })}`,
        {
            method: 'GET',
            headers,
        }
    );
};

const getUnreadNotificationsCount = async (
    authToken: string,
    filters: NotificationFilter = {}
) => {
    const headers = await getAuthHeaders(authToken);
    return fetch(
        `api/Notifications/GetUnreadNotificationsCount?${getSearchParamsString(
            filters
        )}`,
        {
            method: 'GET',
            headers,
        }
    );
};

export { getNotifications, getUnreadNotificationsCount };
