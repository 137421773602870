import { USER_TYPE } from '../../constants/identifiers';

const blockedBrokerRoutes = [
    '/submit-application',
    '/certification',
    '/certification-portal',
];

const canUserRoute = (user, path, isFlagged) => {
    if (isFlagged) {
        return false;
    }
    const userType = user.userType;
    switch (userType) {
        case USER_TYPE.Broker:
            return !blockedBrokerRoutes.includes(path);
        default:
            return true;
    }
};

export { canUserRoute };
