import { API_ENDPOINT, getLocalTimeHeader } from './api';

const urlGetConfigAllResources = '/Config/GetURLsAndUploadConstants';
const urlGetFeatureFlags = '/Config/GetFeatureFlags';
const urlGetB2C = '/Config/B2C';
const urlGetGoogleApiKey = '/Config/GetGoogleApiKey';

const getB2CConfig = async () => {
    const response = fetch(`${API_ENDPOINT}${urlGetB2C}`, {
        method: 'GET',
    }).then((response) => response.json());

    return response;
};
const getGoogleApiKey = async () => {
    const response = fetch(`${API_ENDPOINT}${urlGetGoogleApiKey}`, {
        method: 'GET',
    }).then((response) => response.text());

    return response;
};

const getURLsAndUploadConstants = async () => {
    const url = `${API_ENDPOINT}${urlGetConfigAllResources}`;
    const headers = getLocalTimeHeader();

    const response = fetch(url, {
        method: 'GET',
        headers,
    }).then((response) => response.json());

    return response;
};

const getFeatureFlags = async () => {
    const url = `${API_ENDPOINT}${urlGetFeatureFlags}`;

    const headers = getLocalTimeHeader();

    const response = fetch(url, {
        method: 'GET',
        headers,
    })
        .then((response) => response?.json())
        .catch((err) => {
            console.error(err);
            return [];
        });

    return response;
};

export {
    getB2CConfig,
    getURLsAndUploadConstants,
    getFeatureFlags,
    getGoogleApiKey,
};
